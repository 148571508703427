
//存取值未对象是使用
export function setSession(key,value){
	if(typeof value == "object"){
		value = window.JSON.stringify(value);
	}
	sessionStorage.setItem(key, value);
}
export function getSession(key){
	let value = sessionStorage.getItem(key);
	let json = window.JSON.parse(value);

	if(typeof json == "object" && json){
		return json;
	}
	return value;
}
export function clearSession(){
	sessionStorage.clear();
}
export function hasKey(key){//session中是否存在指定key
	if(getSession(key) == "")
		return false;
	return true;
}

//保留两位小数并且小数字体小一些
export function toFix(value){
	var x = value.toFixed(2)
	return x
}