import axios from 'axios'

export var apiBaseUrl

// apiBaseUrl = this.store.state.apiBaseUrl
apiBaseUrl = sessionStorage.getItem("apiBaseUrl")
if (apiBaseUrl == undefined) {
    let test;
    if (window.XMLHttpRequest) {
        test = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        test = new window.ActiveXObject();
    } else {
        alert("请升级至最新版本的浏览器");
    }
    if (test != null) {
        test.open("GET", "static/config.json?r="+Math.random(), true);
        test.send(null);
        test.onreadystatechange = function () {
            if (test.readyState == 4 && test.status == 200) {
                let obj = JSON.parse(test.responseText);
                apiBaseUrl = obj.apiBaseUrl
                window.sessionStorage.setItem("apiBaseUrl", apiBaseUrl)
				//每次发送请求都会带上
				//打包前缀
				if(process.env.NODE_ENV == 'production'){
					axios.defaults.baseURL = apiBaseUrl
				}
				//开发前缀'/api'
				else if(process.env.NODE_ENV == 'development'){
					axios.defaults.baseURL = apiBaseUrl
				}
				
            }
        };
    }
}
