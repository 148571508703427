//路由配置
import Vue from 'vue'
import VueRouter from 'vue-router'

//路由懒加载
const Home = () => import('@/pages/home/Home.vue')
const Product = () => import('@/pages/product/Product.vue')
const News = () => import('@/pages/news/News.vue')
const Solution = () => import('@/pages/solution/Solution.vue')
const Aboutus = () => import('@/pages/aboutus/Aboutus.vue')
const companyIntro = () => import('@/components/content/aboutus/companyIntro.vue')
const recruiting = () => import('@/components/content/aboutus/recruiting.vue')
const contactUs = () => import('@/components/content/aboutus/contactUs.vue')
const appIntro = () => import('@/components/content/aboutus/appIntro.vue')
const Login = () => import('@/pages/login/Login.vue')
const Regist = () => import('@/pages/login/Register.vue')
const ForgetPwd = () => import('@/pages/login/ForgetPwd.vue')
const NewsDetail = () => import('@/pages/detail/NewsDetail.vue')
const ProductDetail = () => import('@/pages/detail/ProductDetail.vue')
const SolutionDetail = () => import('@/pages/detail/SolutionDetail.vue')
const searchValue = () => import('@/pages/search/searchValue.vue')
const ProductBuy = () => import('@/pages/product/ProductBuy.vue')
const EnterpriseCertification = () => import('@/components/content/home/EnterpriseCertification.vue')
const Message = () => import('@/pages/message/messagecenter.vue')
const Agreement = () => import('@/components/content/agreement/agreement.vue')
const Agreement1 = () => import('@/components/content/agreement/agreement1.vue')
const highCompanyEvaluation = () => import('@/pages/highCompanyEvaluation/highCompanyEvaluation.vue')
const highCEResult = () => import('@/pages/highCompanyEvaluation/highCEResult.vue')
const policyMatch = () => import('@/pages/policyMatch/policyMatch.vue')

//通过vue.use安装插件
Vue.use(VueRouter)

const routes = [
	//redirect重定向
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		component: Home,
		meta: { title: '首页' }
	},
	{
		path: '/product',
		component: Product,
		name: 'product',
		meta: { title: '产品中心' }
	},
	{
		path: '/news',
		component: News,
		name: 'news',
		meta: { title: '新闻中心' }
	},
	{
		path: '/solution',
		component: Solution,
		name: 'solution',
		meta: { title: '解决方案' }
	},
	{
		path: '/aboutus',
		component: Aboutus,
		meta: { title: '公司简介' },
		children: [
			{
				path: '',
				redirect: '/companyIntro',
			},
			{
				path: '/companyIntro',
				component: companyIntro,
				name: 'companyintro',
				meta: { title: '公司简介' }
			},
			{
				path: '/recruiting',
				component: recruiting,
				name: 'recruiting',
				meta: { title: '招聘中心' }
			},
			{
				path: '/contactUs',
				component: contactUs,
				name: 'contactus',
				meta: { title: '联系我们' }
			},{
				path: '/appIntro',
				component: appIntro,
				name: 'appintro',
				meta: { title: '应用简介' }
			}
		]
	},
	{
		path: '/login',
		component: Login,
		name: 'login',
		meta: { title: '登录' }
	},
	{
		path: '/regist',
		component: Regist,
		name: 'regist',
		meta: { title: '注册' }
	},
	{
		path: '/forget_password',
		component: ForgetPwd,
		name: 'forget_password',
		meta: { title: '忘记密码' }
	},
	{
		path: '/news/newsdetail',
		component: NewsDetail,
		name: 'newsdetail',
		meta: { title: '新闻详情' }
	},
	{
		path: '/prodcut/productdetail',
		component: ProductDetail,
		name: 'productdetail',
		meta: { title: '产品详情' }
	},
	{
		path: '/SolutionDetail',
		component: SolutionDetail,
		name: 'solutiondetail',
		meta: { title: '解决方案详情' }
	},
	{
		path: '/searchValue',
		component: searchValue,
		name: 'searchValue',
		meta: { title: '搜索' }

	},
	{
		path: '/productBuy',
		component: ProductBuy,
		name: 'productbuy',
		meta: {
			title: '购买商品',
			keepAlive: true
		}
	},
	{
		path: '/enterpriseCertification',
		component: EnterpriseCertification,
		name: 'enterpriseCertification',
		meta: { title: '企业认证' }
	},
	{
		path: '/order',
		name: 'order',
		meta: { title: '我的订单' },
		component: () => import('@/pages/product/OrderList.vue')
	},
	{
		path: '/message',
		name: 'message',
		component: Message,
		meta: { title: '消息中心' }
	},
	{
		path: '/account',
		name: 'account',
		component: () => import('@/pages/userinfo/userinfo.vue'),
		meta: { title: '账户管理' }
	},
	{
		path: '/agreement',
		name: 'agreement',
		component: Agreement,
		meta: { title: '用户协议'}
	},
	{
		path: '/agreement1',
		name: 'agreement1',
		component: Agreement1,
		meta: { title: '隐私协议'}
	},
	{
		path: '/highCompanyEvaluation',
		component: highCompanyEvaluation,
		name: 'highCompanyEvaluation',
		meta: { title: '高企评测' }
	},{
		path: '/highCEResult',
		component: highCEResult,
		name: 'highCEResult',
		meta: { title: '高企评测结果' }
	},{
		path:'/policyMatch',
		component: policyMatch,
		name:'policyMatch',
		meta: { title: '政策解读' }
	}
]

const router = new VueRouter({
	routes,
	mode: 'hash',
	linkActiveClass: 'isActive',
})

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = '江苏数字矩阵科技有限公司-' + to.meta.title
	}
	next()
})

//重复点击报错问题，重写路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

export default router