<!--
	Description: 导航栏
-->
<template>
    <div class="header">

      <transition name="el-zoom-in-top">
      <div class="nav transition-box" v-show="navtabisShow">
        <div class="logo">
          <img class="nav_image1" src="~/assets/images/nav/logo_zqt.png" />
          <img class="nav_image_phone" src="~/assets/images/login/zqt_logo.png" alt="找不到资源">
          <!-- <img class="nav_image_phone" src="~/assets/images/images_phone_show/logo_mini.png" /> -->
        </div>
        <!-- 屏幕大于1200显示tab -->
        <div class="navtabbox">
          <navTabList> </navTabList>
        </div>
        <!-- 屏幕小于1200px显示tab -->
        <div class="navtab_phone">
            <!-- <i class="el-icon-s-fold"></i> -->
            <el-dropdown  trigger="click">
              <span class="el-dropdown-link">
                {{navtabidx}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item 
                  v-for="(item,index) in navtabList" 
                  @click.native="choosenavtab(index)"
                  :key="index">{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
        </div>
        <div class="header_right">
          <div class="searchinput">
            <input
              type="text"
              :placeholder="placeholder"
              @keyup.enter="search"
              v-model="searchvalue"
            />
            <img
              class="search_in_input"
              src="~/assets/images/nav/search.png"
              @click="search"
            />
          </div>
          <!-- <img class="nav_image1" src="~/assets/images/nav/cart.png" /> -->
          <!-- @mouseenter="userboxShow = true" @mouseleave="controlShow" -->
          <div class="userlogo" @click="GoLogin">
            <img
              class="nav_image2"
              :src="
                this.userinfo != null
                  ? this.imgUrlFront + this.userinfo.ImageUrl
                  : require('@/assets/images/nav/user.png')
              "
              @error="defImg($event)"
            /><span class="nickname">{{ loginstatu }}</span>
            <userBox class="userbox" @changeDrawer="changeDrawer" @changeDrawer1="changeDrawer1"></userBox>
          </div>
        </div>
      </div>
      </transition>
      <!-- 收藏弹出框层 -->
      <el-drawer
        :visible.sync="drawer"
        :with-header="false"
        :show-close="true" 
        size="50%"
      >
        <div class="collection" style="padding: 10px;">
          <div class="collection_top">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
              <el-tab-pane
                v-for="(item, index) in tabList"
                :key="index"
                :label="item.label" 
                :name="item.name"
                >
                <div ref="articlebox" 
                @scroll="scrollBox($event)"
                style="height: 90vh;overflow-x:auto;-webkit-scrollbar:display:none"
                >
                    <!-- 文章显示内容 -->
                  <div 
                  v-for="(item_in,index_in) in item.content"
                  :key="index_in"
                  @click="toNewsDetail(index_in)"
                  class="singleArticle">
                      <div class="sa_left">
                        <img :src="imgUrlFront+item_in.CoverImgUrl" alt="">
                      </div>
                      <div class="sa_right">
                        <div class="sa_title">{{item_in.ArticleTitle}}</div>
                        <div class="sa_summary">{{item_in.Summary}}</div>
                      </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>

        </div> 
      </el-drawer>
      <!-- 企业认证弹出层 -->
      <el-drawer
        :with-header="false"
        :visible.sync="drawer1"
        direction="ttb"
        size="100%"
        >
        <div class="cm" >
          
          <certificationMaterial v-show="Status==0 || Status == 2" :drawer1="drawer1" @changeDrawer1="changeDrawer1"></certificationMaterial>
          <certificationMaterialShow ref="cemat" v-show="Status!=0 && Status !=2" :drawer1="drawer1" @changeDrawer1="changeDrawer1" :Status="Status"></certificationMaterialShow>

        </div>
      </el-drawer>

    </div>
  
 
</template>

<script>
import navTabList from "@/components/common/nav/navTabList.vue";
import { getSession,setSession } from "@/utils/utils.js";
import userBox from "./userBox.vue";
import certificationMaterial from "./certificationMaterial.vue";
import certificationMaterialShow from "./certificationMaterialShow.vue";
export default {
  name: "navTab",
  data() {
    return {
      searchvalue: "", //搜索框中的值
      loginstatu: "", //登录信息提示
      placeholder: "软件著作权", //搜索输入框中的提示值
      drawer: false,  //收藏弹出层是否显示
      drawer1: false, //企业认证弹出层


      userboxShow:false,//是否展示用户信息块
      boxTimer:'',//计时器
      tabList:[
        {label:'资讯收藏',name:'a_store',content:[]},
        {label:'资讯点赞',name:'a_honor',content:[]}
      ],//tab列表
      tabIndex:0,// tab索引
      activeName: 'a_store',//收藏的活跃索引
      pageSize:100,//页面容量
      pageIndex:1,//页码
      total:'',//总数
      total_pageIndex:'',//总页数
      busy:false,//是否繁忙
      Status:'',
      //手机版样式新增
      navtabList:[
        {name:'首页',path:'/home'},
        {name:'产品中心',path:'/product'},
        {name:'新闻动态',path:'/news'},
        {name:'解决方案',path:'/solution'},
        {name:'关于我们',path:'/aboutus'},
      ],
      navtabidx: '',//选中的navtabList的name
      navtabisShow:true,//控制导航栏是否显示
      scrollValue:0,
    };
    
  },
  computed: {
    
    //用户信息
    userinfo() {
      return this.$store.state.userinfo;
    },
    //图片前缀
    imgUrlFront() {
      return window.sessionStorage.getItem("apiImgUrl");
    },
    isActive_index(){
      return this.$store.state.isActive_index
    }
  },
  created(){
    this.gethotsearch();
    // 用户信息
    let userinfodetail = window.sessionStorage.getItem("userinfo");
    if (userinfodetail !== null) {
      this.loginstatu = this.userinfo.NickName;
    } else {
      this.loginstatu = "登录";
    }

  },
  mounted() {
    this.navtabidx = this.navtabList[this.isActive_index].name
    window.addEventListener("scroll",this.handlescroll,true)
  },
  watch: {
    userinfo(newValue, oldValue) {
      let that = this;
      if (newValue !== null) {
        that.loginstatu = this.userinfo.NickName;
      }
    },
    $route(val,old){
      this.navtabisShow = true
    }
  },
  components: {
    navTabList,
    userBox,
    certificationMaterial,
    certificationMaterialShow
  },
  methods: {
    //下拉显示导航栏 上拉隐藏导航栏
    handlescroll(){
      if(window.pageYOffset >100){
        let nowToTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
        let  scroll = nowToTop - this.scrollValue;
        this.scrollValue = nowToTop
        if(this.nowToTop){
          this.navtabisShow = true
        }else{
          if(scroll < 0  ) this.navtabisShow = true
          else this.navtabisShow = false
        }
      }

    },
    choosenavtab(index){
      this.navtabidx = this.navtabList[index].name
      this.$router.push(this.navtabList[index].path)
      // sessionStorage.setItem('isActive_index',index)
      this.$store.commit('updateIsActiveIndex',index)
    },
    //获取热门搜索内容
    gethotsearch() {
      let request = {
        Version: "",
        JZToken: "",
        UserGuid: "",
      };
      this.$axios("/API/Get_SearchRecordAndHotKeywords", request).then(
        (res) => {
          this.placeholder = res.hotKeywords[0].Keyword;
        }
      );
    },
    //用于子组件修改是否显示---收藏弹出框
    changeDrawer(value) {
      this.drawer = value;
      this.tabList[0].content = []
      this.$axios('/API/GetArticleOpList',{
        Version:'',
        PageIndex:this.pageIndex,
        PageSize: this.pageSize,
        JZToken: this.userinfo.TokenCode,
        UserGuid: this.userinfo.UserGuid,
        OpType: 3
      }).then( res => {
		  res.data.forEach( i => {
			  i.ArticleTitle =  decodeURIComponent(i.ArticleTitle);
			  i.Summary = decodeURIComponent(i.Summary)
			  i.CoverImgUrl = decodeURIComponent(i.CoverImgUrl).split(",");
			  this.tabList[0].content.push(i)
		  })
        this.total = res.count
      })
    },
    //子组件修改是否显示---企业认证弹出框
    changeDrawer1(val,statu){
      this.Status = statu
      this.drawer1 = val
    },
    //去登陆
    GoLogin() {
      if (this.userinfo == null) {
        this.$router.push("/login");
      }
    },
    toNewsDetail(index){
      
      let flnkid = this.tabList[this.tabIndex].content[index].ArticleGuid
      this.drawer = false
      this.$router.push({
        name:'newsdetail',
        query: {
          newsflnkid: flnkid,
        },
      })
      
    },
    //加载更多收藏内容
    scrollBox(event){
      // console.log(event)
    },
    //点击搜索
    search() {
      let keyword = this.searchvalue ? this.searchvalue : this.placeholder
      this.$store.commit('searchvalue',keyword)
    
      let newsList = [];
      this.$axios("/API/SearchContent", {
        Version: "",
        KeyWord: keyword,
        PageIndex: 1,
        PageSize: 9,
        JZToken: "",
        UserGuid: "",
      }).then((res) => {
        res.data.forEach((item) => {
	        item.ArticleTitle = decodeURIComponent(item.ArticleTitle)
          item.CoverImgUrl = item.CoverImgUrl.split(",", 1);
          item.Tags = item.Tags.split(",");
          newsList.push(item);
        });
        this.$store.commit('issearchclick',true)
        this.$router.push({
          name: "searchValue",
          query: {
            searchvalue: keyword,
            total: res.count,
            newsdata: JSON.stringify(newsList),
            items: JSON.stringify(res.items),
          },
        });
      });
    },
    //默认加载头像
    defImg(event){
      let img = event.srcElement
      img.src = require('@/assets/images/nav/header.png')
      img.onerror = null
    },
    //点击请求收藏内容
    handleClick(tab,event) {
      let index = tab.index
      this.tabIndex = tab.index
      let optype = ''   //请求参数type  3-收藏 1-点赞
      if(index == 0){
        optype = 3
      }else if(index == 1){
        optype = 1
      }
      this.pageIndex = 1
      this.$axios('/API/GetArticleOpList',{
        Version:'',
        PageIndex:this.pageIndex,
        PageSize: this.pageSize,
        JZToken: this.userinfo.TokenCode,
        UserGuid: this.userinfo.UserGuid,
        OpType: optype
      }).then( res => {
      this.tabList[index].content = []
        res.data.forEach( i => {
          i.ArticleTitle =  decodeURIComponent(i.ArticleTitle);
          i.Summary = decodeURIComponent(i.Summary)
          i.CoverImgUrl = decodeURIComponent(i.CoverImgUrl).split(",");
          this.tabList[index].content.push(i)
        })
          
          // this.tabList[index].content = res.data
          this.total = res.count
        }) 
      }
  },

};
</script>

<style lang="scss" scoped>
  @import '@/components/common/nav/navTab.scss';
  // .el-drawer{
  //    overflow: scroll;  
  // }
</style>
