import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './imgConfig.js'
import 'lib-flexible'
import '@babel/polyfill'
import 'default-passive-events'
import axios from "./request.js"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animated from 'animate.css'

import htmlToPdf from "./utils/htmltoPDF.js"

ElementUI.Dialog.props.lockScroll.default = false;
// ElementUI.Drawe
Vue.use(axios)
Vue.use(ElementUI);
Vue.use(animated)
Vue.use(htmlToPdf)

Vue.config.productionTip = false
Vue.prototype.$store = store
Vue.prototype.project_title = "数字矩阵" //平台公共标题
Vue.prototype.Version = ''				//版本号



new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')

