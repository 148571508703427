// import config from './config/js'
// console.log(config)
var apiImgUrl

apiImgUrl = sessionStorage.getItem("apiImgUrl")
if (apiImgUrl == undefined) {
    let test;
    if (window.XMLHttpRequest) {
        test = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        test = new window.ActiveXObject();
    } else {
        alert("请升级至最新版本的浏览器");
    }
    if (test != null) {
        test.open("GET", "static/config.json", true);
        test.send(null);
        test.onreadystatechange = function () {
            if (test.readyState == 4 && test.status == 200) {
                let obj = JSON.parse(test.responseText);
                apiImgUrl = obj.apiImgUrl
                window.sessionStorage.setItem("apiImgUrl", apiImgUrl)
            }
        };
    }
}

/* export function getImgUrlFront(){

	return apiImgUrl
} */

/* export default {
	install: function(Vue){
		Vue.prototype.$apiImgUrl = () => { getImgUrlFront() }
	}
} */