<!--Author: zjy   
	Date: 2020.12.30
	Description: 导航栏块tab
	History: 暂无
-->
<template>
	<div id="tabs_list">
		<ul>
			<li class="navTabList" v-for="(item,index) in tabList" :key="index"  :class="isActive_index==index?'isActive':'noActive'"  @click="itemClick(index)">
				<div>{{item.name}}</div>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'navTabList',
		data(){
			return{
				tabList:[
					{name:'首页',path:'/home'},
					{name:'产品中心',path:'/product'},
					{name:'新闻动态',path:'/news'},
					{name:'解决方案',path:'/solution'},
					{name:'关于我们',path:'/aboutus'},
				],					//tab栏列表
				// isActive_index:0,	//isActive索引	
			}
		},
		created(){
			let active = sessionStorage.getItem('isActive_index')
			if(active == null){
				this.$store.commit('updateIsActiveIndex',0)
			}else{
				this.$store.commit('updateIsActiveIndex',active)
			}
		},
		computed:{
			isActive_index(){
				return this.$store.state.isActive_index
			}
		},
		methods:{
			itemClick(index){
				this.$router.push(this.tabList[index].path)
				sessionStorage.setItem('isActive_index',index)
				this.$store.commit('updateIsActiveIndex',sessionStorage.getItem('isActive_index'))
			}
		},
		
		watch:{
			'$route': function(){
				document.body.scrollTop = 0
				document.documentElement.scrollTop = 0
			},
		},
		
	}
</script>

<style lang="scss" scoped>
	@media (min-width: 1201px){
		#tabs_list{
			ul{
				width: 100%;
				height: 70px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.navTabList{
				width: 124px;
				height: 70px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #010101;
				position: relative;
				cursor: pointer;
			}
			.noActive:hover{
				color: rgb(58, 134, 255);
			}
			.isActive{
				background-color: #0094ff;
				transition:.5s;
				color: #fff;
			}
		}
		
	}
	@media (max-width: 1200px){
		#tab_list{
			display: none;
		}
	}

	// #tabs_list ul{
	// 	width: 100%;
	// 	height: 70px;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// }
	// #tabs_list .navTabList{
	// 	width: 124px;
	// 	height: 70px;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	font-size: 16px;
	// 	font-family: Microsoft YaHei;
	// 	font-weight: bold;
	// 	color: #010101;
	// 	position: relative;
	// 	cursor: pointer;
	// }
	
	// #tabs_list .noActive:hover{
	// 	color: rgb(58, 134, 255);
	// }
	// #tabs_list .isActive{
	// 	background-color: #0094ff;
	// 	transition:.5s;
	// 	color: #fff;
	// }
</style>
