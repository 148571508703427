import axios from 'axios'
import qs from 'qs'
import { apiBaseUrl } from './config.js'
if (apiBaseUrl != null || apiBaseUrl != undefined || apiBaseUrl != "") {
	//每次发送请求都会带上
	//打包前缀
	if (process.env.NODE_ENV == 'production') {
		axios.defaults.baseURL = apiBaseUrl
	}
	//开发前缀
	else if (process.env.NODE_ENV == 'development') {
		axios.defaults.baseURL = apiBaseUrl
	}
}

//请求拦截器
axios.interceptors.request.use(config => {
	//发送请求之前做些什么
	return config
}, function (error) {
	return Promise.reject(error)
});

//响应拦截器
axios.interceptors.response.use(res => {
	// 例:在两百范围内的任何状态码会触发此功能
	// 返回响应数据
	if (res.status == 201) {
		this.$confirm('未登录或登录状态失效', '提示', {
			confirmButtonText: '去登录',
			cancelButtonText: '过会再去',
			type: 'warning'
		}).then(() => {
			this.$router.push('/login')
		}).catch(() => {
		});
	}
	return res
}, function (error) {
	// 例：任何超出两百范围的状态代码都会触发此功能
	// 返回响应数据
	return Promise.reject(error)
})

// 封装axios ---------------
function apiAxios(url, params, responseType) {
	if (responseType != null && responseType != undefined) {
		axios.defaults.responseType = responseType
	}
	return new Promise((resolve, reject) => {
		axios.post(url, qs.stringify(params))
			.then(res => {
				resolve(res.data);
			}).catch(err => {
				reject(err.data)
			})
	})
}

export default {
	install: function (Vue) {
		Vue.prototype.$axios = (url, params, responseType) => apiAxios(url, params, responseType)
	}
}