import Vue from 'vue'
import Vuex from 'vuex'
import {getSession} from '../utils/utils.js'
import aboutstore from '@/pages/aboutus/aboutstore.js'
import solutionstore from '@/pages/solution/solutionstore.js'
//1.安装插件
Vue.use(Vuex)
//2.创建对象
const store = new Vuex.Store({
	state:{
		// token:window.sessionStorage.getItem('token'),	//用户token
		userinfo: getSession('userinfo'),//用户信息
		isActive_index:null,//首页索引
		issearchclick:false,//用于记录点击
		searchvalue:'',//搜索的值
		
	},
	mutations:{
		updateIsActiveIndex(state,newIndex){
			state.isActive_index = newIndex
		},
		/* token(state,payload){
			state.token = payload
		}, */
		userinfo(state,payload){
			state.userinfo = payload
		},
		issearchclick(state,payload){
			state.issearchclick = payload
		},
		searchvalue(state,payload){
			state.searchvalue = payload
		}
	},//相当于自定义组件中的methods，只能做同步的操作
	//对state中的数据进行修改
	actions:{
		userinfo(context,n){
			context.commit('userinfo',n)
		},
	},//异步操作，例如ajax请求
	//使用commit 触发 mutations
	getters:{
	},//相当于自定义组件中的computed
	modules:{
		aboutstore,
		solutionstore
	},
})
//3.导出store对象
export default store