<template>
  <div id="relateus">
    <div class="relateus" :style="'top:' + scrollTop + 'px;'" >
      <el-popover
        placement="left-start"
        title="联系号码"
        width="200"
        trigger="hover">
        <div class="phonenumber">025-85207326</div>
        <div class="righticon icon_phone" slot="reference"><i class="el-icon-phone-outline"></i>
      </div>
      </el-popover>
      <div class="aline"></div>
      <el-popover
        placement="left-start"
        width="140"
        trigger="hover">
        <div class="codebox">
          <div class="codetitle">关注我们 </div>
          <img class="code" src="~/assets/images/home/code.jpg" />
          <img class="codes" src="~/assets/images/home/gzhcode.jpg" />
        </div>
        <div class="righticon icon_scan" slot="reference"><i class="el-icon-full-screen"></i><div class="scanlinebox"><div class="scanline"></div></div></div>
      </el-popover>
      <el-backtop :right=20 :bottom=100>Top</el-backtop>
    </div>

    <!-- <div class="goTop" style="height: 100%; overflow-x: hidden">
      
    </div> -->
  </div>
</template>

<script>
export default {
  name:'relateus',
  data () {
    return {
      scrollTop: 280,
    }
  },
  computed:{
  },
  created(){
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods:{
    handleScroll(e) {
      if(e.target.documentElement != undefined){
        this.scrollTop =  (e.target.documentElement.scrollTop || e.target.body.scrollTop) + 280;
      }
    },
  }
}
</script>

<style scoped lang='scss'>
// .goTop .el-backtop {
//   width: 75px;
//   height: 75px;
//   font-size: 24px;
// }
  @import './relateus.scss';
</style>
