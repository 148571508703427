<!--
	Description: 企业认证弹出框（填写）
-->
<template>
    <div class="ceMat">
    
        <div class="ceMat_in">
          <div class="ceMat_top">
            企业认证
          </div>
          <el-form
            label-position="left"
            :model="ruleForm_confirmCompany"
            status-icon
            :rules="rules"
            ref="ruleForm_confirmCompany"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div class="confComp_section1">
              <el-form-item
                label="营业执照"
                prop="BusinessLicenseUrl"
                class="uploadfile"
              >
                <el-upload
                  :action="apiBaseUrl+'/API/UploadFiles'"
                  list-type="picture-card"
                  :data="imguploaddata"
                  :on-success="uploadfile"
                  :limit="1"
                  ref="upload"
                  :on-exceed="exceedimg"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>

                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :modal="false" :visible.sync="dialogVisible">
                  <img class="imgclass" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <el-form-item label="企业名称" prop="company_name">
                <el-input
                  type="text"
                  v-model="ruleForm_confirmCompany.company_name"
                  placeholder="例:xxx有限公司"
                ></el-input>
              </el-form-item>

              <el-form-item label="企业类型" prop="company_type">
                <el-select
                  v-model="ruleForm_confirmCompany.company_type"
                  placeholder="请选择企业类型"
                >
                  <el-option
                    v-for="(item, index) in typeList"
                    :key="index"
                    :label="item.DictName"
                    :value="item.DictID"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="企业规模" prop="company_size">
                <el-select
                  v-model="ruleForm_confirmCompany.company_size"
                  placeholder="请选择企业类型"
                >
                  <el-option
                    v-for="(item, index) in sizeList"
                    :key="index"
                    :label="item.DictName"
                    :value="item.DictID"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="法人姓名" prop="ArtificialPersonName">
                <el-input
                  type="text"
                  v-model="ruleForm_confirmCompany.ArtificialPersonName"
                  placeholder="例:xxx有限公司"
                ></el-input>
              </el-form-item>
              <el-form-item label="税号" prop="TaxpayerIdentityNumber">
                <el-input
                  type="text"
                  v-model="ruleForm_confirmCompany.TaxpayerIdentityNumber"
                  autocomplete="off"
                  placeholder="即统一社会信用代码"
                ></el-input>
              </el-form-item>
            
              
            </div>
            <div class="confComp_section2">
              <el-form-item label="注册日期" prop="createDate">
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  placeholder="选择日期"
                  v-model="ruleForm_confirmCompany.createDate"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>

              <el-form-item  label="街道/园区"  prop="streetPark">
                <el-select v-model="ruleForm_confirmCompany.streetPark" 
                filterable 
                remote
                :remote-method="toSearchRList"
                placeholder="请输入街道/园区关键词"
                :loading="loading"
                v-el-select-loadmore="loadmore"
                >
                  <el-option
                    v-for="(item,index) in streetParkList"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="注册省/市/区" prop="selectedOptions">
                <el-cascader
                  :options="regionData"
                  v-model="ruleForm_confirmCompany.selectedOptions"
                  separator=" - "
                  @change="handleChange"
                ></el-cascader>
              </el-form-item>

              <el-form-item label="注册详细地址" prop="regAddressExtend">
                <el-input
                  type="text"
                  v-model="ruleForm_confirmCompany.regAddressExtend"
                  placeholder="请输入注册详细地址"
                ></el-input>
              </el-form-item>
              <el-form-item label="办公省/市/区">
                <el-cascader
                  :options="regionData"
                  v-model="selectedOptions1"
                  separator=" - "
                  @change="handleChange1"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="办公详细地址" prop="workAddressExtend">
                <el-input
                  type="text"
                  v-model="ruleForm_confirmCompany.workAddressExtend"
                  placeholder="办公详细地址"
                ></el-input>
              </el-form-item>

              <el-form-item label="经营范围" prop="BusinessScope">
                <el-input
                  type="text"
                  v-model="ruleForm_confirmCompany.BusinessScope"
                  placeholder="企业经营范围"
                ></el-input>
              </el-form-item>
              <div style="color: #ff4949;">注: 请仔细核对系统自动识别填充信息,目前仅支持江苏省</div>
            </div>

            <div
              class="operationBtn closeopbtn1"
            >
              <div class="opBtnin">
                <el-button
                  class="btn1 btn_commit"
                  style="background:#07c160;color:#fff;"
                  @click="sumbit_CompanyCertification('ruleForm_confirmCompany')"
                  >提交认证</el-button
                >
                <el-button class="btn_reset" @click="resetForm('ruleForm_confirmCompany')"
                  >重置</el-button
                >
                <el-button
                  class="btn1 btn_close"
                  style="color: #fff; background-color: #ff4949;"
                  @click="closeDrawer"
                  >关闭</el-button>
              </div>
            </div>
          </el-form>




        </div>
    </div>
</template>

<script>
  import {getSession,setSession} from '@/utils/utils.js'
  export default {
    name:"certificationMaterial",
    directives:{
      'el-select-loadmore':{
        bind(el,binding){
          const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
          console.log(SELECTWRAP_DOM)
          SELECTWRAP_DOM.addEventListener('scroll', function () {
            const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
            if(condition){
              binding.value()
            }
          }) 
        }
      }
    },
    data(){
        const validataTaxpayerIdentityNumber = (rule,value,callback) => {
          const TaxpayerIdentityNumberRule = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;//15/18位
          if(!TaxpayerIdentityNumberRule.test(this.ruleForm_confirmCompany.TaxpayerIdentityNumber)){
            callback(new Error("请输入合法税号"))
          }
          else{
            callback() 
          }
        }
        return{
          pickerOptions: {
            disabledDate(time) {
              return time.getTime() > Date.now();
            },
          },
          ruleForm_confirmCompany: {
            company_name: "", //公司名称
            company_type: "", //公司类型
            company_size: "", //公司规模
            ArtificialPersonName: "", //法人姓名
            TaxpayerIdentityNumber: "", //税号
            createDate: "", //注册日期
            regAddressID: "", //注册省市区code
            regAddressExtend: "", //注册详细地址
            workAddressID: "", //工作 省市区code
            workAddressExtend: "", //工作 详细地址
            streetPark:"",//所属街道
            BusinessScope: "", //经营范围
            BusinessLicenseUrl: "", //营业执照url
            selectedOptions: [], //注册省市区--显示
          },
          rules: {
            //公司认证信息验证validator: validateComName
            company_name: [
              { required: true, message: "企业名称不可为空", trigger: "blur" },
              {
                min: 3,
                max: 30,
                message: "企业名称长度在3-20个字符间",
                trigger: "blur",
              },
            ],
            company_type: [
              { required: true, message: "请选择企业类型", trigger: "change" },
            ],
            company_size: [
              { required: true, message: "请选择企业规模", trigger: "change" },
            ],
            ArtificialPersonName: [
              { required: true, message: "法人姓名不可为空", trigger: "blur" },
            ],
            TaxpayerIdentityNumber: [
              {
                required: true,
                message: "税号/统一社会信用代码 不可为空",
                trigger: "blur",
              },
              {
                required: true,
                validator: validataTaxpayerIdentityNumber,
                trigger: 'blur'
              }
            ],
            createDate: [
              { required: true, message: "请选择日期", trigger: "change" },
            ],
            selectedOptions: [
              { required: true, message: "请选择省/市/区", trigger: "change" },
            ],
            regAddressExtend: [
              { required: true, message: "注册地址不可为空", trigger: "blur" },
            ],
            workAddressExtend: [],
            BusinessScope: [
              { required: true, message: "经营范围不可为空", trigger: "blur" },
            ],
            BusinessLicenseUrl: [
              { required: true, message: "营业执照不可为空", trigger: "change" },
            ],
            streetPark: [
              { required: true, message: "请选择街道/园区", trigger:"change" }
            ]
          },
          imguploaddata: {
            Version: "",
            FileTypeID: 8,
            ParamsString: "",
            JZToken: "",
            UserGuid: "",
          }, //上传图片需要的参数

          dialogImageUrl: "",
          dialogVisible: false,
          disabled: false,
          typeList: [], //企业类型列表
          sizeList: [], //企业规模列表
          regionData: [],//省市区地址
      
          selectedOptions1: [], //办公省市区--显示
          streetParkList: [],//园区/街道
          pageIndex:1,//页码
          pageSize:10,//页面容量
          loading:false,//是否正在从远程获取数据
          searchvalue:'',//搜索街道/园区的搜索值
          userinfo:'',
          apiBaseUrl:window.sessionStorage.getItem('apiBaseUrl'),
        }
    },
    props:['drawer1'],
    computed:{
     //图片前缀
      imgUrlFront() {
        return window.sessionStorage.getItem("apiImgUrl");
      },
    },
    created(){
      this.userinfo = getSession('userinfo')
      this.imguploaddata = {
          Version: "",
          FileTypeID: 8,
          ParamsString: "",
          JZToken: this.userinfo.TokenCode,
          UserGuid: this.userinfo.UserGuid,
      }
      this.init()
    },
    methods:{
      init(){
        this.$axios("/API/GetDict", {
          Version: "",
          DictTypeID: "YHXX_QYLX",
        }).then((res) => {
          this.typeList = res.data;
        });
        this.$axios("/API/GetDict", {
          Version: "",
          DictTypeID: "YHXX_QYGM",
        }).then((res) => {
          this.sizeList = res.data;
        });
        this.$axios("/API/GetAddressDictList2", {
          Version: "",
        }).then((res) => {
          this.regionData = res.data;
        });
      },
      //上传图片成功回调赋值
      uploadfile(val) {
        this.ruleForm_confirmCompany.BusinessLicenseUrl = val.data.split("|")[1];
        let newimgpath  = this.imgUrlFront+this.ruleForm_confirmCompany.BusinessLicenseUrl
        this.$axios('/Recognition/BDRecognition',{
          Version:'',
          ImgUrl:newimgpath,
          JZToken: this.userinfo.TokenCode,
          UserGuid: this.userinfo.UserGuid
        }).then( res => {
          this.ruleForm_confirmCompany.company_name = res.data.CompanyName  //公司名称
          let year = res.data.RegisteredDate.split('年')[0]
          let month = res.data.RegisteredDate.split('年')[1].split('月')[0]
          let day = res.data.RegisteredDate.split('年')[1].split('月')[1].split('日')[0]
          this.ruleForm_confirmCompany.createDate = year+'-'+month+'-'+day   //日期
          this.ruleForm_confirmCompany.ArtificialPersonName = res.data.ArtificialPersonName //法人
          this.ruleForm_confirmCompany.BusinessScope = res.data.BusinessScope //经营范围
          this.ruleForm_confirmCompany.TaxpayerIdentityNumber = res.data.TaxpayerIdentityNumber //税号
          let AddressName = res.data.AddressName
          this.ruleForm_confirmCompany.regAddressExtend = res.data.AddressName  

        })
      },
      
      handleRemove() {
        this.$refs["upload"].clearFiles();
        this.ruleForm_confirmCompany.BusinessLicenseUrl = ''
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      exceedimg() {
        alert("上传图片已超过最大限制(最多上传一张");
      },
      //点击获取省市区 code
      handleChange(val) {
        this.ruleForm_confirmCompany.regAddressID = val[2];
      },
      handleChange1(val) {
        this.ruleForm_confirmCompany.workAddressID = val[2];
      },
      //街道/园区输入内容触发搜索请求
      toSearchRList(query){
        if(query !== ''){
          this.searchvalue = query
          this.loading = true
          this.$axios('/API/SearchAreaByName',{
            Version:'',
            AreaName:query,
            PageIndex:this.pageIndex,
            PageSize:this.pageSize,
            JZToken:this.userinfo.TokenCode,
            UserGuid: this.userinfo.UserGuid
          }).then(res => {
            console.log(res)
            this.loading = false
            let arr = []
            res.data.forEach(i =>{
              let newobj = {}
              newobj.label = i.ProvinceName+'-'+i.CityName+'-'+i.RegionName+'-'+i.AreaName
              newobj.value = i.AreaCode+','+i.FLnkID
              arr.push(newobj)
            })
            this.streetParkList = arr
          })
        }else{
          this.streetParkList = []
        }
       
      },
      //加载更多数据
      loadmore(){
        this.pageIndex = ++this.pageIndex
        this.$axios('/API/SearchAreaByName',{
          Version:'',
          AreaName:this.searchvalue,
          PageIndex:this.pageIndex,
          PageSize:this.pageSize,
          JZToken:this.userinfo.TokenCode,
          UserGuid: this.userinfo.UserGuid
        }).then(res => {
          let arr = []
          res.data.forEach(i =>{
              let newobj = {}
              newobj.label = i.ProvinceName+'-'+i.CityName+'-'+i.RegionName+'-'+i.AreaName
              newobj.value = i.AreaCode+','+i.FLnkID
              arr.push(newobj)
          })
          this.streetParkList = this.streetParkList.concat(arr)
        })
      },
      //关闭弹出框
      closeDrawer(){
        this.$emit('changeDrawer1',!this.drawer1,this.userinfo.Status)
      },
      //企业认证
      sumbit_CompanyCertification(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.company_certification();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      //提交企业认证
      company_certification() {
        let rc = this.ruleForm_confirmCompany;
        let request = {
          Version: "",
          CompanyName: rc.company_name,
          TaxpayerIdentityNumber: rc.TaxpayerIdentityNumber,
          ArtificialPersonName: rc.ArtificialPersonName,
          CompanyTypeID: rc.company_type,
          Regdate: rc.createDate,
          RegAddressID: rc.regAddressID,
          RegAddressExtend: rc.regAddressExtend,
          OfficeAddressID: rc.workAddressID,
          OfficeAddressExtend: rc.workAddressExtend,
          BusinessScope: rc.BusinessScope,
          CompanySizeID: rc.company_size,
          BusinessLicenseUrl: rc.BusinessLicenseUrl,
          AreaCode:rc.streetPark.split(',')[0],
          AreaGuid:rc.streetPark.split(',')[1],
          JZToken: this.userinfo.TokenCode,
          UserGuid: this.userinfo.UserGuid,
        };
        this.$axios("/API/Sumbit_UserAuthentication", request).then((res) => {
          this.$axios('/API/Get_UserInfo',{
            Version:'',
            JZToken:this.userinfo.TokenCode,
            UserGuid: this.userinfo.UserGuid
          }).then(res => {
            console.log(res)
            this.$store.dispatch("userinfo", res.data[0]);
            setSession("userinfo", res.data[0]);
            this.$message("提交成功");
             setTimeout(() => {
              this.$emit('changeDrawer1',!this.drawer1,res.data[0].Status)
            },900)
          })
         
        });
      },
      resetForm(formName) {
        this.ruleForm_confirmCompany.BusinessLicenseUrl = ''
        this.$refs["upload"].clearFiles();
        this.$refs[formName].resetFields();
      },
    }

  }
</script>

<style lang="scss" >
  @media(min-width: 901px){
    @import './certificationMaterial.scss';
  }
  @media(max-width: 900px){
    @import './certificationMaterial_phone.scss';
  }
    
</style>