<!--
	Description: 企业认证弹出框（仅展示）
-->
<template>
    <div class="ceMat">
    
        <div class="ceMat_in">
          <div class="ceMat_top">
            企业认证
          </div>
          <el-form
            label-position="left"
            :model="ruleForm_confirmCompany"
            status-icon
            ref="ruleForm_confirmCompany"
            label-width="100px"
            class="demo-ruleForm"
          >
            <span class="cemat_status" v-if="Status==1" style="color: red;">认证中</span>
            <span class="cemat_status" v-if="Status==3" style="color: green;">已认证</span>
            <div class="cm_left">
              <el-form-item label="企业名称">
                <el-input
                  type="text"
                  :value="ruleForm_confirmCompany.CompanyName"
                  readonly
                ></el-input>
              </el-form-item>

              <el-form-item label="企业类型">
                <el-input
                  type="text"
                  :value="ruleForm_confirmCompany.CompanyTypeName"
                  readonly
                ></el-input>
              </el-form-item>
                <el-form-item label="企业规模">
                <el-input
                  type="text"
                  :value="ruleForm_confirmCompany.CompanySizeName"
                  readonly
                ></el-input>
              </el-form-item>
                <el-form-item label="法人姓名">
                <el-input
                  type="text"
                  :value="ruleForm_confirmCompany.ArtificialPersonName"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="税号">
                <el-input
                  type="text"
                  :value="ruleForm_confirmCompany.TaxpayerIdentityNumber"
                  readonly
                ></el-input>
              </el-form-item>
                <el-form-item label="注册日期">
                <el-input
                  type="text"
                  :value="ruleForm_confirmCompany.RegdateDisplay"
                  readonly
                ></el-input>
              </el-form-item>
            <el-form-item label="街道/园区">
                <el-input
                  type="text"
                  :value="ruleForm_confirmCompany.AreaName"
                  readonly
                ></el-input>
              </el-form-item>
          
            </div>
            <div class="cm_right">
            <el-form-item label="注册省/市/区">
                <el-input
                  type="text"
                  :value="ruleForm_confirmCompany.RegAddressName"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="注册详细地址">
                <el-input
                  type="text"
                  resize="none"
                  :value="ruleForm_confirmCompany.RegAddressExtend"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="办公省/市/区">
                <el-input
                  type="text"
                  :value="ruleForm_confirmCompany.OfficeAddressName"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="办公详细地址">
                <el-input
                  type="text"
                  resize="none"
                  :value="ruleForm_confirmCompany.OfficeAddressExtend"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="经营范围">
                <el-input
                  type="textarea"
                  autosize
                  resize="none"
                  :value="ruleForm_confirmCompany.BusinessScope"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="营业执照">
              
                <el-image
                    style="width: 100px; height: 100px"
                    v-if="srcList.length>0"
                    :src="src"
                    :preview-src-list="srcList"
                    >
                </el-image>
              </el-form-item>
            
          
            </div>

            <div
              class="operationBtn closeopbtn"
            >
              <el-button
                class="btn1"
                @click="closeDrawer"
            >关闭</el-button
              >
              
            </div>
          </el-form>




        </div>
    </div>
</template>

<script>
  import {getSession} from '@/utils/utils.js'
  export default {
    name:"certificationMaterialShow",
    data(){
        return{
          ruleForm_confirmCompany: {},
          src:require('@/assets/images/nav/header.png'),//营业执照
          srcList:[],//点击放大
        }
    },
    props:['drawer1','Status'],
    computed:{
      userinfo(){
        return this.$store.state.userinfo    
      },
      //图片前缀
      imgUrlFront() {
        return window.sessionStorage.getItem("apiImgUrl");
      },
    },
    created(){
      this.init()
    },
    methods:{
      init(){
        this.$axios('/API/Get_UserAuthentication',{
            Version:'',
            CompanyUserFLnkID:this.userinfo.UserGuid,
            JZToken:this.userinfo.TokenCode,
            UserGuid:this.userinfo.UserGuid
        }).then( res => {
            this.ruleForm_confirmCompany = res.data[0]
            this.src = this.imgUrlFront+res.data[0].BusinessLicenseUrl
            this.srcList[0] = this.imgUrlFront+res.data[0].BusinessLicenseUrl
        })
      },
      closeDrawer(){
        this.$emit('changeDrawer1',!this.drawer1,this.userinfo.Status) 
      }
    
            
    }

  }
</script>

<style lang="scss" >
    @media( min-width: 901px){
      @import './certificationMaterialShow.scss';
    }
    @media( max-width: 900px){
      @import './certificationMaterialShow_phone.scss';
    }
      
    
</style>