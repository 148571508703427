<template>
  <div id="SimpleInfo">
    <div class="topinfo">
      <img :src="headerUrl" alt="" @error="defImg($event)" />
      <div class="namePhone">
        <div class="uname">
          {{ nickname }}
          <!-- <span class="approve">{{!userinfo?'请登录':(userinfo.Status==3?'已认证':(userinfo.Status==1?'认证中':'未认证'))}}</span> -->
          <span v-if="userinfo" @click="FindCertification">
            <span class="approve" v-if="userinfo.Status == 3">
              <img style="width: 65px;height: 19px;border-radius: 0;background: none;border: none;" src="~/assets/images/nav/confirm.gif" alt="">
            </span>
            <span class="approve" style="background:#000;" v-else-if="userinfo.Status == 4">已封禁</span>
            <span class="approve" style="background:#ccc;" v-else-if="userinfo.Status == 2">未通过</span>
            <span class="approve" style="background:#ff4949;" v-else-if="userinfo.Status == 1">认证中</span>
            <span class="approve" style="background:#ccc;" v-else>未认证</span>
          </span>
        </div>
        <div class="uphone"> {{ phone }} 
          <!-- <span class="unSignin" v-if="isSignin" @click="checkIn">签到</span> -->
          <span class="unSignin" @click="checkIn">{{isSignin ? '签到' : '已签到'}}</span>
          <!-- <span class="Signin" v-if="!isSignin">已签到</span> -->
          </div>
      </div>
    </div>
    <div class="bottominfo">
      <div
        v-for="(item, index) in toolsList"
        :key="index"
        class="singletools"
        @click="clickevent(item.name)"
        @mouseenter="mouseovertool(index,'hover')"
        @mouseleave="mouseovertool(index,'')"
      >
        <div class="toolsicon"><img :src="item.showimgUrl" alt="" /></div>
        <div class="toolstitle">{{ item.name }}</div>
      </div>
    </div>
    <div class="unlogin" v-if="!userinfo">
      <div class="tipunlogin">
        <div>未登录？<span @click="GoLogin">点击登录</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userBox",
  data() {
    return {
      isSignin:false, //false 不可以签到 true 可以签到
      SignArr:[],
      toolsList: [
        // {
        //   id: 0,
        //   imgUrl: require("@/assets/images/nav/user.png"),
        //   selectedimgUrl: require("@/assets/images/nav/user_hover.png"),
        //   name: "账号管理",
        // },
        {
          id: 1,
          imgUrl: require("@/assets/images/nav/tools_1.png"),
          showimgUrl: require("@/assets/images/nav/tools_1.png"),
          selectedimgUrl: require("@/assets/images/nav/tools_1_selected.png"),
          name: "我的订单",
        },
        {
          id: 2,
          showimgUrl: require("@/assets/images/nav/tools_2.png"),
          imgUrl: require("@/assets/images/nav/tools_2.png"),
          selectedimgUrl: require("@/assets/images/nav/tools_2_selected.png"),
          name: "我的收藏",
        },
        {
          id: 3,
          showimgUrl: require("@/assets/images/nav/tools_3.png"),
          imgUrl: require("@/assets/images/nav/tools_3.png"),
          selectedimgUrl: require("@/assets/images/nav/tools_3_selected.png"),
          name: "消息通知",
        },
        {
          id: 4,
          showimgUrl: require("@/assets/images/nav/tools_4.png"),
          imgUrl: require("@/assets/images/nav/tools_4.png"),
          selectedimgUrl: require("@/assets/images/nav/tools_4_selected.png"),
          name: "退出登录",
        },
      ],
    };
  },
  props: ["drawer","drawer1"],
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
    nickname() {
      return this.userinfo ? this.userinfo.NickName : "";
    },
    phone() {
      return this.userinfo ?  '账号：' + this.userinfo.MobileNo : " ";
    },
    imgUrlFront() {
      return window.sessionStorage.getItem("apiImgUrl");
    },
    headerUrl() {
      return this.userinfo
        ? this.imgUrlFront + this.userinfo.ImageUrl
        : require("@/assets/images/nav/user.png");
    },

  },
  created() {
   
  },
  mounted(){
     if(this.userinfo){
       this.getCheckIn()
     }
      
  },
  methods: {
    // 查看认证
    FindCertification(){
      //
      this.$emit('changeDrawer1',!this.drawer1,this.userinfo.Status)
    },
    //鼠标经过修改图标
    mouseovertool(index,mousestatu){
      if(mousestatu == 'hover'){
        this.toolsList[index].showimgUrl = this.toolsList[index].selectedimgUrl
      }else{
        this.toolsList[index].showimgUrl = this.toolsList[index].imgUrl
      }
    },
    //弹出框
    clickevent(value) {
      if (value == "我的收藏") {
        this.$emit("changeDrawer", !this.drawer);
      } else if (value == "退出登录") {
        this.exitlogin();
      } else if (value == "我的订单") {
        this.$router.push({ name: "order" });
      } else if (value == "消息通知") {
        this.$router.push({ name: "message" });
      } else if(value=="账号管理"){
        this.$router.push({ name: "account" });
      }
    },
    //退出登录
    exitlogin() {
      window.sessionStorage.removeItem("userinfo");
      this.$store.state.userinfo = ""
      this.$router.go(0);
      // this.$router.push('/home')
    },
    GoLogin() {
      if (this.userinfo == null) {
        this.$router.push("/login");
      }
    },
    //使用默认头像
    defImg(e) {
      let img = e.srcElement;
      img.src = require("@/assets/images/nav/header.png");
      img.onerror = null;
    },
    //签到
    checkIn(){
      this.$axios('/API/CheckIn',{
        Version:'',
        MobileNo:this.userinfo.MobileNo,
        JZToken:this.userinfo.TokenCode,
        UserGuid:this.userinfo.UserGuid
      }).then(res => {
        console.log(res)
        if(res.status == 200){
          this.$message({
            message: '签到成功',
            type: 'success'
          });
        this.isSignin = false
        }else if(res.status == 202){
          this.$message({
            message: '请勿重复签到',
            type: 'warning'
          });
          this.isSignin = false
        }
      })
    },
    //获取签到记录
    getCheckIn(){
        let flag = false
        let year = new Date().getFullYear()
        let month = new Date().getMonth()+1
        let date = new Date().getDate()
        this.$axios('/API/GetCheckIn',{
          Version:'',
          Year: year,
          Month: month,
          JZToken: this.userinfo.TokenCode,
          UserGuid: this.userinfo.UserGuid
        }).then( res => {
          if(res.status == 200){
            this.SignArr = res.data
            let todayString = year + '-' + (month <10 ? '0'+month:month) + '-' + (date<10 ? '0'+date : date)
            res.data.forEach( item => {
              if(todayString == item.CheckInTimeDisplay){
                flag = true
              }
            })
            if(flag){
              this.isSignin = false
            }else{
              this.isSignin = true
            }
          }
        })


    }
  },
  watch: {
    //监听userinfo.登录后有值执行获取签到列表
    userinfo(oldV,newV){
      this.getCheckIn()
 
    }
  },
};
</script>

<style lang="scss" scoped>
#SimpleInfo {
  width: 328px;
  height: 308px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) 100% 100% no-repeat;
 
  background-color: #eee;
  .unlogin {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 308px;
    background-color: rgba(#f5f9f8, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2rpx 4rpx rgba(0,0,0,.16);

    .tipunlogin{
      color: #000;  
      font-size: 18px;
      span{
        color:#3a86ff;
        text-decoration: underline;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
  .topinfo {
    width: 328px;
    height: 108px;
    background-color: #3a86ff;
    background-image: url(../../../assets/images/nav/top_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #eee;
      margin: 0 9px 0 20px;
    }
    .namePhone {
      .uname {
        font-size: 16px;
        color: #ffffff;
        display: flex;
        .approve {
          width: 65px;
          height: 19px;
          border-radius: 19px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 12px;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .uphone {
        font-size: 12px;
        color: #ffffff;
        margin-top: 10px;
        span{
          margin-right: 30px;
          display: inline-block;
          height: 22px;
          padding: 0px 8px;
          line-height: 22px;
          border-radius: 8px;
          text-align: center;
          margin-left: 6px;
          background: rgba(red,.16);
        }
      }
    }
  }
  .bottominfo {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .singletools {
      margin-top: 20px;
      margin-left: 14px;
      width: 92px;
      height: 70px;
      background: #ffffff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: #3a86ff;
        color: #fff;
      }
      .toolsicon {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .toolstitle {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

</style>
