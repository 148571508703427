<template>
  <div id="index_Page">
      <nav-tab></nav-tab>
    <router-view></router-view>

    <relateus></relateus>
    <!-- <div class="relateus" :style="'top:' + scrollTop + 'px;'" v-show="adShow">
      <div class="closeleft" @click="closeleft">关闭</div>
      <div class="titles">关注我们</div>
      <div style="margin-top: 10px">全国服务热线</div>
      <div
        style="
          color: #ff2a00;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 14px;
        ">
        025-85207326
      </div>
      <img class="code" src="./assets/images/home/code.jpg" />
      <img class="codes" src="./assets/images/home/gzhcode.jpg" />
    </div> -->
  </div>
</template>

<script>
import navTab from "@/components/common/nav/navTab.vue";
import relateus from "@/components/common/relateus/relateus.vue";
export default {
  name: "App",
  components: {
    navTab,
    relateus,
  },
  data() {
    return {
      // scrollTop: 280,
      // adShow: true
    };
  },
  created() {
    //获取版本号
    this.$axios("/API/GetVersionNo", "").then((res) => {
      this.Version = res.data;
    });
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll, true);
  },
  computed: {},
  methods: {
    // handleScroll(e) {
    //   if(e.target.documentElement != undefined){
    //     this.scrollTop =  (e.target.documentElement.scrollTop || e.target.body.scrollTop) + 280;
    //   }
    // },
    // closeleft(){
    //   this.adShow = false
    // }
  },
};
</script>

<style>
@import url("assets/css/global.css");
#index_Page {
  height: 100%;
}
/* 滚动条样式设置 */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: #f5f8f7;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}
.closeleft{
  position: absolute;
  right: 5px;
  top: -23px;
  cursor: pointer;

}

@media(max-width: 901px){
  .el-message-box{
    width: 100%;
    max-width: 350px;
  }
  .el-notification{
    opacity: .8;
    width: 210px !important;
  }
}
</style>
