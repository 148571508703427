
const state = {
	aboutus_index:0,//aboutus页面的二级索引
	totop:null,//aboutus页面距离顶部的距离
}
const mutations = {	
	aboutus_index(state,payload){
		state.aboutus_index = payload
	},
	totop(state,payload){
		state.totop = payload
	}
}
const actions = {
	aboutus_index(context,payload){
		context.commit('aboutus_index',payload)
	}
}

export default {
	namespaced: true, // 开启命名空间
	state,
	mutations,
	actions,
}